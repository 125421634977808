<template>
  <v-sheet :color="wsDASHBACKGROUND" class=" ">
    <!-- MOBILE SIZE-->
    <div class="d-flex justify-space-between align-center pl-3 pr-6 py-1 " >
      <div>
        <v-btn @click="footerAction('home')" icon :color="selectedMenu === 'home' ?  wsACCENT : ''">
          <v-icon>mdi-home</v-icon>
        </v-btn>
      </div>

      <v-btn @click="footerAction('courses')" icon :color="selectedMenu === 'courses' ?  wsACCENT : ''" >
        <v-icon>mdi-school</v-icon>
      </v-btn>

      <v-btn @click="footerAction('profile')" icon :color="selectedMenu === 'profile' ?  wsACCENT : ''" >
        <v-icon>mdi-account</v-icon>
      </v-btn>

      <messagesMenu @open="footerAction('messenger')" :color="selectedMenu === 'messenger' ?  wsACCENT : ''"  />
    </div>

  </v-sheet>
</template>

<script>
import messagesMenu from "@/components/messenger/messagesMenu";
import {mapMutations,mapState} from "vuex";
export default {
  name: "headerDash",
  components : {
    messagesMenu,
  },
  data() {
    return {
      selectedMenu : 'home'
    }
  },
  computed : {
    ...mapState('dashboard', ['sideMenu','pageTitle']),
  },
  methods: {
    ...mapMutations('dashboard', ['TOGGLE_SIDEBAR']),

    footerAction(action) {
      this.selectedMenu = action
      this.$store.state.messenger.displayMessenger = false
      switch(action) {
        case 'home' : this.$router.push(this.$route) ; break
        case 'courses' : this.$router.push(this.businessDashLink('')) ; break
        case 'profile' : this.$router.push(this.businessDashLink('profile')) ; break
        case 'messenger' : this.$store.state.messenger.displayMessenger = true ; break
      }
    }

  },
}
</script>

<style scoped>


.header {
  color: #2c3e50;
}
.userDropdown {
  margin : 0px 25px;
}

</style>