<template>
<div>
  <v-hover #default="{hover}">
    <ws-link :to="itemRoute">
      <v-sheet
          @click="handleAction"
          class="d-flex align-center justify-space-between pr-3"
          v-ripple
          style="transition: background-color 0.2s ease"
          :color="hover || isSelected ? (wsACCENT + '22' ) : 'transparent'"
      >
        <v-sheet

            class="d-flex align-center pointer"
            style="position: relative; "
            color="transparent"

            height="40"
        >

          <v-sheet :color="isSelected ? wsACCENT : 'transparent'"
                   class="mr-5 "
                   style="border-radius: 0 4px 4px 0;"
                   height="26"
                   width="6px" />

          <v-sheet v-if="isChild"   width="41" height="40" style="position:relative; margin-left: 10px"   color="transparent" >
            <v-sheet v-if="!isLast" style="position: absolute;" color="transparent"  :style="`border-left : 1px solid ${wsDARKLIGHT} !important;`"  height="40" width="20" />
            <v-sheet color="transparent" style="border-bottom-left-radius: 50%" :style="`border-left : 1px solid ${wsDARKLIGHT} !important; border-bottom : 1px solid ${wsDARKLIGHT} !important`"  height="20" width="20"   />
          </v-sheet>

          <v-icon v-if="item.children" :color="wsACCENT" class="ml-n5 "  >
            mdi-menu-{{ expanded ? 'down' : 'right' }}
          </v-icon>

          <v-icon v-if="!isChild" class="mr-4"
                  :class="[{'ml-n1' : item.children}]" :color="wsACCENT">{{ item.icon }}</v-icon>

          <h5 :class="[{'ml-n2' : isChild}]" class="py-2 wsACCENT">{{ item.text || 'menuItem'}}</h5>

        </v-sheet>

        <v-sheet class="d-flex align-center px-3" height="24" style="border-radius: 50px" v-if="item.counter > 0" :color="wsACCENT">
          <h6 class="wsWHITE">{{  item.counter  }}</h6>
        </v-sheet>

      </v-sheet>
    </ws-link>

  </v-hover>


  <v-expand-transition>
    <div v-if="!isChild && !!item.children && expanded">
      <menu-element
          @click="$emit('click', child);"
          v-for="(child, i) in item.children" :key="i +'child'"
          :selected-item="selectedItem"
          :item="child"
          :admin="admin"
          :is-last="i === item.children.length - 1"
          :is-page-navigation="isPageNavigation"
          is-child
      />
    </div>
  </v-expand-transition>


</div>
</template>

<script>
// import menuElement from './menuElement.vue'
export default {
  name: "menuElement",
  components: {
    // menuElement
  },
  props : {
    item : {
      type : Array,
      default () { return [] }
    },
    selectedItem : {},
    isChild : {
      type : Boolean,
      default : false
    },
    admin : {
      type : Boolean,
      default : false,
    },
    isLast : {
      type : Boolean,
      default : false
    },
    isPageNavigation : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      expanded: false,
    }
  },
  computed : {
    isSelected() {
      return this.selectedItem === this.item.value
    },
    itemRoute() {
      if (!this.isPageNavigation) {
        return (this.item.route || this.item.path) && (this.item.route || this.businessDashLink(this.item.path || '' , this.admin ))
      } else {
        return this.businessDashLink(this.$store.state.dashboard.pageNavigationRoot + '/' + this.item.path, this.admin)
      }

    },
  },
  methods : {
    handleAction () {
      this.$emit('click' , this.item)
      this.$emit('action' , this.item)

      if ( this.item.children) {
        this.expanded = !this.expanded
      }
    },
  }
}
</script>



<style scoped>

</style>