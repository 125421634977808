import { render, staticRenderFns } from "./headerDash.vue?vue&type=template&id=4ad80113&scoped=true&"
import script from "./headerDash.vue?vue&type=script&lang=js&"
export * from "./headerDash.vue?vue&type=script&lang=js&"
import style0 from "./headerDash.vue?vue&type=style&index=0&id=4ad80113&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ad80113",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBtn,VIcon,VSheet})
