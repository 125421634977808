<template>
  <div>
    <v-btn icon @click="openSideMessenger" :color="color" >
      <v-badge  :content="newMessagesCount"
                :value="newMessagesCount"
                :color="wsATTENTION"
                overlap >
        <v-icon :color="color"  >mdi-email-outline </v-icon>
      </v-badge>
    </v-btn>
    <v-slide-x-transition>
        <v-sheet  elevation="1" v-if="displayMessenger"
                  :class="!SM ? 'desktopSize' : 'mobileSize'"
                  :color="wsBACKGROUND" class="pa-3">

          <div v-if="!SM" class="d-flex justify-space-between align-center py-3 px-3">
            <v-btn v-if="selectedChatId" small @click="returnBack" icon><v-icon>mdi-chevron-left</v-icon></v-btn>
            <v-chip  v-if="selectedChatId && newMessagesCount > 0" class="px-3 mr-3" small dark :color="wsLIGHTACCENT"><h5>{{  newMessagesCount }} </h5></v-chip>
            <div v-if="!selectedChatId" >
              <h5> {{ $t('Messenger') }}</h5>
              <h6 class="font-weight-light" > {{ newMessagesCount > 0 ? ($t('NewMessages') + ': ' + newMessagesCount) : $t('YouHaveNoNewMessages') }} </h6>
            </div>
            <div v-if="selectedChatId" style="width: 100%" class="d-flex align-center justify-space-between" >


              <div>
                <h5> {{ selectedChat.name }} </h5>
                <h6 class="font-weight-light" > {{ $t(selectedChat.type) }} </h6>
              </div>

              <v-avatar class="mr-3" size="32" :color="wsLIGHTACCENT">
                <v-img  v-if="selectedChat.img" :src="selectedChat.img" transition="xxx"></v-img>
                <v-icon v-else dark>mdi-account</v-icon>
              </v-avatar>

            </div>
            <v-btn @click="$store.state.messenger.displayMessenger = false" icon><v-icon>mdi-close</v-icon></v-btn>
          </div>
          <div class="fill-height" :class="!SM ? ' pb-15' : '' ">

            <v-sheet  v-if="!selectedChatId && !selectedChatId "
                      class="wsRoundedHalf overflow-y-auto fill-height ">

              <messengerSidebar compact />

            </v-sheet>
            <messengerChat compact v-if="selectedChatId" />

          </div>

        </v-sheet>
      </v-slide-x-transition>
  </div>
</template>

<script>

import {mapActions, mapMutations, mapState} from "vuex";
import messengerChat from "@/components/messenger/UI/messengerChat";
import messengerSidebar from "@/components/messenger/UI/messengerSidebar";

export default {
  name: "messagesMenu",
  props : {
    color : {
      type : String,
      default : ''
    }
  },
  components : {
    messengerSidebar,
    messengerChat,
  },
  computed : {
    ...mapState('messenger', [
        'newMessagesCount',
        'recentMessages',
        'sidebarType',
        'selectedChatId',
        'selectedChat',
        'selectedContactId',
        'dashMessengerOpened',
        'displayMessenger'
    ]),
  },
  watch : {
    displayMessenger(value) {
      if (!value && !this.dashMessengerOpened) {
        this.FLUSH_MESSENGER()
      } else {
        if ( !this.sidebarType ) {
          this.$store.state.messenger.sidebarType = 'active_chats'
        }
      }
    },
    dashMessengerOpened(value) {
      if (value) {
        this.$store.state.messenger.displayMessenger = false
      }
    }
  },
  methods : {
    ...mapActions('messenger', [
      'GET_RECENT_MESSAGES',
    ]),
    ...mapMutations('messenger', ['FLUSH_MESSENGER']),

    openSideMessenger(){
      if ( this.SM ) {
        return this.$emit('open')
      }
      if ( !this.$store.state.messenger.dashMessengerOpened ) {
        this.$store.state.messenger.displayMessenger = true
      }

    },

    returnBack() {
      this.$store.state.messenger.selectedChatId = ''
      this.$store.state.messenger.selectedChat = {}
    },
    selectChat(item) {
      console.log(item)
    }

  },
  async mounted() {
    if ( !this.sidebarType ) {
      this.$store.state.messenger.sidebarType = 'active_chats'
    }
    this.GET_RECENT_MESSAGES()
  }
}
</script>

<style scoped>
.desktopSize {
  top:0;bottom:0;right:0; width: 450px; position: fixed; z-index: 999
}
.mobileSize {
  top:0;
  bottom:44px;
  right:0;
  left:0;
  position: fixed;
  z-index: 999
}
</style>